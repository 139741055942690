<template>
  <div class="data-management">
    <div class="page-title-row">
      <h1 class="title">
        <div class="title-left">
          {{ $t('sideNav.modelTrainingList') }}
        </div>
      </h1>
    </div>
    <div class="table-board">
      <div class="board-title-row">
        <div class="description">
          {{ $t('modelTraining.reminder.training') }}
        </div>
        <div class="button-block">
          <button
            class="btn-m btn-default btn-has-icon"
            @click="triggerCreateDialog"
          >
            <svg-icon
              icon-class="plus"
              class="icon"
            />{{ $t('modelTraining.newGoal') }}
          </button>
        </div>
      </div>
      <empty-info-block
        v-if="modelTrainingGoalList.length === 0"
        :msg="$t('editing.emptyPinboard')"
      />
      <template v-else>
        <training-card
          v-for="(info, idx) in modelTrainingGoalList"
          :training-info="info"
          :key="idx"
          @delete="toggleConfirmDeleteDialog"
        />
      </template>
      <el-pagination
        v-if="paginationInfo.totalPages > 1"
        :total="paginationInfo.totalItems"
        :page-size="paginationInfo.itemPerPage"
        :current-page="paginationInfo.currentPage + 1"
        class="table-pagination"
        layout="prev, pager, next"
        @current-change="changePage"
        @prev-click="changePage"
        @next-click="changePage"
      />
    </div>
    <create-dialog
      v-if="isShowCreateDialog"
      @done="createCompleted($event)"
      @close="triggerCreateDialog"
    />
    <decide-dialog
      v-if="isShowConfirmDialog"
      :content="$t('modelTraining.decideToDeleteTraining')"
      :type="'delete'"
      class="model-delete-dialog"
      @closeDialog="cancelDelete"
      @confirmBtn="deleteTrainingTask"
    />
  </div>
</template>

<script>
import EmptyInfoBlock from '@/components/EmptyInfoBlock'
import CreateDialog from './components/CreateDialog'
import TrainingCard from './components/TrainingCard'
import DecideDialog from '@/components/dialog/DecideDialog'
import { getModelTrainingGoalList, deleteModelTrainingGoal } from '@/API/ModelTraining'
import { mapGetters } from 'vuex'
import { Message } from 'element-ui'

export default {
  name: 'TrainingList',
  components: {
    EmptyInfoBlock,
    CreateDialog,
    TrainingCard,
    DecideDialog
  },
  data () {
    return {
      isLoading: false,
      isShowCreateDialog: false,
      isShowConfirmDialog: false,
      modelTrainingGoalList: [],
      trainingInfoId: null,
      intervalFunction: null,
      paginationInfo: {
        currentPage: 0,
        totalPages: 0,
        totalItems: 0,
        itemPerPage: 15
      }
    }
  },
  computed: {
    ...mapGetters('userManagement', ['getCurrentAccountId', 'getCurrentGroupId'])
  },
  mounted () {
    this.fetchData()
  },
  destroyed () {
    this.clearPolling()
  },
  methods: {
    fetchData (page = 0) {
      this.isLoading = true
      const currentGroupId = this.getCurrentGroupId
      return getModelTrainingGoalList(currentGroupId, page)
        .then(({ trainingGoalDtoList, pagination }) => {
          this.paginationInfo = pagination
          const processList = trainingGoalDtoList.filter((model) => !model.canDelete)
          if (processList.length && !this.intervalFunction) {
            this.startPolling()
          } else if (!processList.length) {
            this.clearPolling()
          }
          this.modelTrainingGoalList = trainingGoalDtoList
        }).finally(() => {
          this.isLoading = false
        })
    },
    changePage (page) {
      this.clearPolling()
      this.fetchData(page - 1)
    },
    triggerCreateDialog () {
      this.isShowCreateDialog = !this.isShowCreateDialog
    },
    toggleConfirmDeleteDialog (goal_id) {
      this.trainingInfoId = goal_id
      this.isShowConfirmDialog = true
    },
    cancelDelete () {
      this.isShowConfirmDialog = false
    },
    deleteTrainingTask () {
      deleteModelTrainingGoal(this.trainingInfoId)
        .then(() => {
          this.fetchData()
          Message({
            message: this.$t('modelTraining.deleteTrainingSuccessfully'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        }).finally(() => {
          this.cancelDelete()
        })
    },
    goToTrainingGoalPage (goalInfo) {
      this.$router.push({
        name: 'TrainingGoal',
        params: {
          account_id: this.getCurrentAccountId,
          group_id: this.getCurrentGroupId,
          goal_id: goalInfo.id
        }
      })
    },
    async createCompleted (currentGoalId) {
      await this.fetchData()
      let currentGoal = this.modelTrainingGoalList.filter(goal => goal.id === currentGoalId)[0]
      // 創建新的之後要直接進入該目標內
      this.$nextTick(() => this.goToTrainingGoalPage(currentGoal))
    },
    startPolling () {
      this.intervalFunction = window.setInterval(() => {
        this.fetchData(this.paginationInfo.currentPage)
      }, 5000)
    },
    clearPolling () {
      if (this.intervalFunction) window.clearInterval(this.intervalFunction)
      this.intervalFunction = null
    }
  }
}
</script>

<style lang="scss" scoped>
.data-management {
  .table-board {
    padding: 0;
    background: transparent;
    box-shadow: unset;
  }

  .board-title-row {
    display: block;

    .description {
      margin-bottom: 16px;
      font-size: 13px;
      line-height: 18px;
      color: #DDDDDD;
    }

    .btn-m {
      font-weight: 600;
    }
  }

  .model-delete-dialog {
    >>> .dialog-content-wrapper > .content {
      color: #FFF;
    }
  }
}

</style>
