import { render, staticRenderFns } from "./CreateDialog.vue?vue&type=template&id=5d8643db&scoped=true&"
import script from "./CreateDialog.vue?vue&type=script&lang=js&"
export * from "./CreateDialog.vue?vue&type=script&lang=js&"
import style0 from "./CreateDialog.vue?vue&type=style&index=0&id=5d8643db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8643db",
  null
  
)

export default component.exports