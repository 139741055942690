<template>
  <div class="card">
    <div class="card__header">
      <div class="title">
        {{ trainingInfo.name }}
      </div>
    </div>
    <div class="card__content">
      <div class="card__list">
        <div class="card__list--left">
          <div class="info-wrapper">
            <div class="label">
              {{ $t('modelTraining.trainingTarget') }}：
            </div>
            <div class="text">
              {{ trainingInfo.trainingTargetName }}
            </div>
          </div>
          <div class="info-wrapper">
            <div class="label">
              {{ $t('modelTraining.modelAmount') }}：
            </div>
            <div class="text">
              {{ trainingInfo.numberOfTasks }}
            </div>
          </div>
        </div>
        <div class="card__list--right">
          <training-status
            v-if="!trainingInfo.canDelete"
            class="training-status"
          >
            {{ $t('modelTraining.training') }}
          </training-status>
          <div class="action-wrapper">
            <a
              href="javascript:void(0)"
              class="link"
              @click="goToTrainingGoalPage(trainingInfo.id)"
            >{{ $t('modelTraining.trainingContent') }}</a>
            <a
              v-if="trainingInfo.canDelete"
              href="javascript:void(0)"
              class="link"
              @click="$emit('delete', trainingInfo.id)"
            >{{ $t('modelTraining.trainingDelete') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrainingStatus from './TrainingStatus'
import { mapGetters } from 'vuex'

export default {
  name: 'TrainingCard',
  components: {
    TrainingStatus
  },
  props: {
    trainingInfo: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('userManagement', ['getCurrentAccountId', 'getCurrentGroupId'])
  },
  methods: {
    goToTrainingGoalPage (goal_id) {
      this.$router.push({
        name: 'TrainingGoal',
        params: {
          account_id: this.getCurrentAccountId,
          group_id: this.getCurrentGroupId,
          goal_id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #1C292B;
  background: #1C292B;
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    .sub-title {
      margin-left: auto;
      color: #999999;
      font-size: 12px;
      font-weight: 400;

      &--bold {
        font-weight: 600;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &--left, &--right {
      display: flex;
      align-items: center;
    }

    &--right {
      margin-left: auto;
    }
  }

  .info-wrapper {
    display: flex;
    margin-right: 12px;

    .label {
      font-size: 12px;
      font-weight: 600;
      color: #999999;
    }

    .text {
      font-size: 12px;
      color: #999999;
    }
  }

  .training-status {
    margin: 0 16px 0 auto;
  }

  .action-wrapper {
    .link {
      font-weight: bold;
      font-size: 14px;
      padding: 0 15px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &:not(:last-child) {
        border-right: 1px solid #E0E0E0;
      }
    }
  }
}
</style>
