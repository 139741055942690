<template>
  <div class="training-status-block">
    <spinner size="14" />
    <span class="status">
      <slot />
    </span>
  </div>
</template>

<script>

export default {
  name: 'TrainingStatus'
}
</script>

<style lang="scss" scoped>
.training-status-block {
  display: flex;
  align-items: center;

  ::v-deep .spinner-block {
    margin-right: 4px;
    padding: 0;

    .spinner-circle > .sp-circle::before {
      background-color: $theme-color-primary;
    }
  }

  .status {
    font-size: 12px;
    color: #CCCCCC;
  }
}
</style>
