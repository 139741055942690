<template>
  <div class="dialog-box">
    <div class="dialog-inner-box">
      <div @click="closeDialog">
        <svg-icon
          icon-class="close"
          class="icon dialog-close"
        />
      </div>
      <div class="dialog-content">
        <div class="title">
          {{ $t('modelTraining.createNewTraining') }}
        </div>
        <!-- training name-->
        <div class="setting-block">
          <div class="setting-block__title">
            {{ $t('modelTraining.trainingName') }}
          </div>
          <div class="setting-block__content">
            <input-verify
              v-validate="`required|max:${max}`"
              v-model="trainingName"
              :placeholder="$t('editing.pleaseEnterName')"
              class="setting-block__input"
              name="trainingName"
            />
          </div>
        </div>
        <div class="setting-block">
          <div class="setting-block__title">
            {{ $t('modelTraining.trainingDataSetting') }}
            <remind-popover
              :content="$t('modelTraining.reminder.dataSetting')"
              icon-name="description"
            />
          </div>
          <div class="setting-block__content">
            <!-- select data source -->
            <div class="select-wrapper">
              <div class="setting-block__subtitle">
                {{ $t('miniApp.dataSource') }}
              </div>
              <default-select
                v-validate="'required'"
                :option-list="dataSourceOptionList"
                :placeholder="$t('miniApp.chooseDataSource')"
                :is-disabled="isLoadingDataSource"
                v-model="sourceDataSourceId"
                filterable
                class="setting-block__select"
                name="dataSourceId"
                @change="fetchDataFrameList"
              />
              <div
                v-show="errors.has('dataSourceId')"
                class="error-text"
              >
                {{ errors.first('dataSourceId') }}
              </div>
            </div>
            <!-- select data frame -->
            <div class="select-wrapper">
              <div class="setting-block__subtitle">
                {{ $t('miniApp.dataFrame') }}
              </div>
              <div class="select-wrapper__container">
                <default-select
                  v-validate="'required'"
                  :option-list="dataFrameOptionList"
                  :placeholder="$t('miniApp.chooseDataFrame')"
                  :is-disabled="!!(isLoadingDataFrame || !sourceDataSourceId)"
                  v-model="sourceDataframeId"
                  filterable
                  class="setting-block__select"
                  name="dataframeId"
                  @change="fetchDataColumnList"
                />
              </div>
              <div
                v-show="errors.has('dataframeId')"
                class="error-text"
              >
                {{ errors.first('dataframeId') }}
              </div>
            </div>
            <!-- select data target -->
            <div class="select-wrapper">
              <div class="setting-block__subtitle">
                {{ $t('modelTraining.trainingTarget') }}
                <remind-popover
                  :content="$t('modelTraining.reminder.supportType')"
                  icon-name="data-explanation"
                />
              </div>
              <div class="select-wrapper__container">
                <default-select
                  v-validate="'required'"
                  :option-list="dataColumnOptionList"
                  :placeholder="$t('editing.selectColumn')"
                  :is-disabled="isLoadingDataFrame || !sourceDataframeId || isLoadingDataColumn"
                  v-model="sourceDataColumnId"
                  class="setting-block__select"
                  name="dataColumnId"
                />
                <div
                  v-if="sourceDataColumn && sourceDataColumn[0].distinctValues"
                  class="primary-target-container"
                >
                  {{ $t('model.primaryTarget') }}
                  <remind-popover
                    :content="$t('model.reminder.primaryClassification')"
                    icon-name="data-explanation"
                  />
                  <div class="primary-target-container__radio">
                    <div
                      v-for="(value, index) in sourceDataColumn[0].distinctValues"
                      :key="index"
                      class="input-radio-group"
                    >
                      <input
                        :id="value"
                        :checked="primaryTarget === value"
                        :value="value"
                        class="input-radio"
                        type="radio"
                        @change="primaryTarget = value"
                      >
                      <label
                        :for="value"
                        class="input-radio-label"
                      >
                        {{ value }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-show="errors.has('dataColumnId')"
                class="error-text"
              >
                {{ errors.first('dataColumnId') }}
              </div>
            </div>
            <div
              v-if="sourceDataColumn"
              class="select-wrapper"
            >
              <div class="setting-block__subtitle">
                {{ $t('model.trainingType') }}
              </div>
              <div class="setting-block__text">
                {{ $t(`modelInfo.${sourceDataColumn[0].trainingType.toLowerCase()}Model`) }}
                <remind-popover
                  :content="$t(`model.reminder.${sourceDataColumn[0].trainingType.toLowerCase()}Model`)"
                  icon-name="data-explanation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="dialog-button-block">
          <button
            class="btn btn-outline"
            @click="closeDialog"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            :disabled="isProcessing"
            class="btn btn-default"
            @click="createTraining"
          >
            <span v-if="isProcessing">
              <svg-icon icon-class="spinner" />
              {{ $t('button.processing') }}
            </span>
            <span v-else>{{ $t('button.built') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputVerify from '@/components/InputVerify'
import DefaultSelect from '@/components/select/DefaultSelect'
import RemindPopover from '@/components/popover/RemindPopover.vue'
import { statsTypeOptionList } from '@/utils/general'
import { getDataSourceList, getDataFrameById } from '@/API/DataSource'
import { getModelTrainingSet } from '@/API/Model'
import { createModelTrainingGoal } from '@/API/ModelTraining'
import { mapGetters } from 'vuex'
import { Message } from 'element-ui'
import SvgIcon from '@/components/SvgIcon'

export default {
  name: 'CreateDialog',
  inject: ['$validator'],
  components: {
    InputVerify,
    DefaultSelect,
    RemindPopover,
    SvgIcon
  },
  data () {
    return {
      isProcessing: false,
      isLoadingDataSource: false,
      isLoadingDataFrame: false,
      isLoadingDataColumn: false,
      trainingName: '',
      sourceDataSourceId: null,
      sourceDataframeId: '',
      sourceDataColumnId: null,
      dataSourceOptionList: [],
      dataFrameOptionList: [],
      dataColumnOptionList: [],
      primaryTarget: null
    }
  },
  computed: {
    ...mapGetters('userManagement', ['getCurrentGroupId']),
    max () {
      return this.$store.getters['validation/fieldCommonMaxLength']
    },
    isLoading () {
      return this.isLoadingDataSource || this.isLoadingDataFrame || this.isLoadingDataColumn
    },
    sourceDataColumn () {
      return this.sourceDataColumnId && this.dataColumnOptionList.filter(column => column.value === this.sourceDataColumnId)
    }
  },
  watch: {
    sourceDataColumn: {
      deep: true,
      handler (value) {
        this.primaryTarget = null
        if (value) {
          let target = value[0].distinctValues
          if (value[0].statsType === 'BOOLEAN') {
            this.primaryTarget = true
          } else if (Array.isArray(target)) {
            this.primaryTarget = target[0]
          }
        }
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.fetchDataSourceList()
      // 清空原資料
      this.clearData()
    },
    clearData () {
      this.sourceDataframeId = ''
      this.sourceDataColumnId = null
      this.dataFrameOptionList = []
      this.dataColumnOptionList = []
    },
    fetchDataSourceList () {
      this.isLoadingDataSource = true
      getDataSourceList()
        .then(response => {
          this.clearData()
          this.dataSourceOptionList = response
            .filter(source => source.state === 'ENABLE')
            .map(source => {
              return {
                name: source.name,
                value: source.id
              }
            })
        }).finally(() => this.isLoadingDataSource = false)
    },
    fetchDataFrameList () {
      this.isLoadingDataFrame = true
      getDataFrameById(this.sourceDataSourceId)
        .then(response => {
          this.clearData()
          this.dataFrameOptionList = response
            .filter((frame) => !(frame.joinCount > 1))
            .map(frame => {
              return {
                name: frame.primaryAlias,
                value: frame.id
              }
            })
        })
        .finally(() => this.isLoadingDataFrame = false)
    },
    fetchDataColumnList () {
      this.sourceDataColumnId = null
      this.isLoadingDataColumn = true

      getModelTrainingSet(this.sourceDataframeId)
        .then(response => {
          this.dataColumnOptionList = response.trainingColumnSetDtoList.map(column => {
            return {
              name: `${column.name} (${this.getStatsType(column.type)} - ${this.lowercaseEachLetterExceptFirst(column.trainingType)})`,
              value: column.id,
              statsType: column.type,
              trainingType: column.trainingType,
              distinctValues: column.distinctValues.length ? column.distinctValues : null
            }
          })
        })
        .finally(() => this.isLoadingDataColumn = false)
    },
    getStatsType (statsType) {
      return statsTypeOptionList.filter(element => statsType === element.value)[0].name
    },
    createTraining () {
      if (this.isProcessing || this.isLoading) return
      let currentGoalId
      this.$validator.validateAll().then(isValidate => {
        if (!isValidate) return
        this.isProcessing = true
        createModelTrainingGoal({
          dataSourceId: this.sourceDataSourceId,
          dataFrameId: this.sourceDataframeId,
          groupId: this.getCurrentGroupId,
          name: this.trainingName,
          targetDataColumnId: this.sourceDataColumnId,
          trainingType: this.sourceDataColumn[0].trainingType,
          primaryTarget: this.primaryTarget
        })
          .then(response => {
            currentGoalId = response.currentModelTrainingGoalId
            Message({
              message: this.$t('message.trainingCreateSuccess'),
              type: 'success',
              duration: 3 * 1000,
              showClose: true
            })
          })
          .finally(() => {
            this.isProcessing = false
            this.$emit('done', currentGoalId)
            this.closeDialog()
          })
      })
    },
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-inner-box {
  padding: 0;
  width: 45%;
  min-width: 650px;
  background: none;
  box-shadow: unset;

  .dialog-close {
    right: 0px;
    top: 7px;
  }
}

.dialog-content {
  margin-bottom: 20px;
  font-size: 18px;

  .title {
    margin-bottom: 25px;
  }

  .setting-block {
    margin-bottom: 12px;
    padding: 24px;
    background: rgba(50, 58, 58, 0.95);
    border-radius: 5px;

    &__title {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 18px;
      text-align: left;
    }

    &__subtitle {
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 14px;
      color: #CCCCCC;
    }

    &__input {
      max-width: 50%;
      ::v-deep .input-verify-text {
        margin-bottom: 0;
      }

      ::v-deep .input-error {
        bottom: -16px;
      }
    }

    &__select {
      min-width: 60%;
    }

    &__text {
      font-size: 16px;
    }

    &__content {
      .select-wrapper {
        text-align: left;
        &:not(:last-of-type) {
          margin-bottom: 24px;
        }
        &__container {
          display: flex;
          align-items: center;
          .warning {
            font-size: 14px;
            color: #FF5C46;
            margin-left: 10px;
          }
        }
      }
      .primary-target-container {
        font-size: 14px;
        font-weight: 600;
        color: #CCCCCC;
        margin-left: 16px;
        &__radio {
          display: flex;
          font-weight: 400;
        }
      }
    }

    ::v-deep .el-input__inner {
      padding-left: 0;
      border-bottom: 1px solid #FFFFFF;

      &::placeholder {
        font-size: 16px;
      }
    }

    ::v-deep .el-input.is-disabled {
      .el-input__inner {
        color: #AAAAAA;
        border-bottom: 1px solid #AAAAAA;

        &::placeholder {
          color: #AAAAAA;
        }
      }

      .el-icon-arrow-up:before {
        color: #AAAAAA;
      }
    }
  }
}
</style>
