import request from '@/utils/publicRequest'
import store from '../store'

/**
 * get model training goal list
 * @query {Number} groupId - group id
 * @query {Number} page - pagination page
 * @query {Number} size - pagination item per page
 */
export function getModelTrainingGoalList (groupId, page = 0, size = 15) {
  return request({
    url: '/model-training-goals',
    method: 'GET',
    params: {
      groupId,
      page,
      size
    }
  })
}

/**
 * create model training goal
 * @param {Object} data
 */
export function createModelTrainingGoal (data) {
  return request({
    url: '/model-training-goals',
    method: 'POST',
    data
  })
}

/**
 * delete model training goal
 */
export function deleteModelTrainingGoal (goalId) {
  return request({
    url: `/model-training-goals/${goalId}`,
    method: 'DELETE'
  })
}

/**
 * get model training goal configuration
 * @query {Number} goalId - goal id
 */
export function getModelTrainingGoalConfiguration (data) {
  return request({
    url: '/model-training-goals/configuration',
    method: 'POST',
    data
  })
}

/**
 * get model training goal correlation
 * @query {Number} goalId - goal id
 */
export function getModelTrainingGoalCorrelation (goalId) {
  return request({
    url: `/model-training-goals/${goalId}/correlation`,
    method: 'GET'
  })
}

/**
 * get model training goal result
 * @query {Number} goalId - goal id
 */
export function getModelTrainingGoalResult (goalId) {
  return request({
    url: `/model-training-goals/${goalId}/results`,
    method: 'GET'
  })
}

/**
 * create model training task
 * @param {Object} data
 */
export function creatModelTrainingTask (data) {
  return request({
    url: '/model-training-tasks',
    method: 'POST',
    data
  })
}

/**
 * close model training task display
 * @query {Number} taskId - task id
 */
export function closeModelTrainingTaskDisplay (task_id) {
  return request({
    url: `/model-training-tasks/${task_id}/display`,
    method: 'PUT'
  })
}

/**
 * delete model training task
 * @query {Number} task_id - task id
 */
export function deleteModelTrainingTask (task_id) {
  return request({
    url: `model-training-tasks/${task_id}`,
    method: 'DELETE'
  })
}

/**
 * register model training task
 * @param {Object} modelInfo -  register model info
 */
export function registerModelTrainingTask (modelInfo) {
  return request({
    url: '/model-training-tasks/register',
    method: 'POST',
    data: {
      ...modelInfo,
      groupId: store.getters['userManagement/getCurrentGroupId'],
      userId: store.state.userManagement.userId
    }
  })
}
